@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Milonga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
//
$cambridge-blue: #8aa8a1ff;
$dark-moss-green: #41521fff;
$xanthous: #fac05eff;
$yinmn-blue: #3d518cff;
$giants-orange: #f46036ff;
//
$line-height-base: 2;
$line-height-sm: 1.5;
$line-height-lg: 3;
//
$table-cell-padding-y: 1.25rem;
$table-cell-padding-x: 1.25rem;
$table-cell-padding-y-sm: .75rem;
$table-cell-padding-x-sm: .75rem;
//
$font-family-base: 'Roboto', sans-serif;
$headings-font-family: 'Bebas Neue', sans-serif;
// $headings-font-family: 'Fredericka the Great', cursive;
//
$primary: #000;
//
$spacer: 1rem !default;
$list-group-item-padding-y: $spacer * 1.5;
$list-group-item-padding-x: $spacer;
//
@import 'bootstrap/scss/bootstrap';

body {
  margin: 0;
  padding: 0;
  padding-top: 4.5rem;
}
